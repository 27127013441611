import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ServiceTemplateDetails from '../components/ServiceTemplateDetails'
import Header from '../components/Header'
import Footer from '../components/Footer'

class ServiceTemplate extends React.Component {
    render() {
        const serviceTemplateData = this.props
        const service = serviceTemplateData.data.allKontentItemService.nodes[0]
        const title = serviceTemplateData.data.kontentItemSiteMetadata.elements.title.value
        const serviceTitle = service.elements.title.value
        const serviceDescription = service.elements.title.value

        return (
            <Layout>
                <div>
                    <Meta title={serviceTitle} postfix={title} description={serviceDescription} />
                    <Header />
                    <ServiceTemplateDetails {...this.props} />
                    <Footer />
                </div>
            </Layout>
        )
    }
}

export default ServiceTemplate

export const pageQuery = graphql`
  query ServiceBySlug($slug: String!) {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }
    allKontentItemService(filter: {elements: {slug: {value: {eq: $slug}}}}) {
      nodes {
        system {
          id
        }
        elements {
            slug {
                value
            }
            title {
                value
            }
            service_item {
                value {
                  ... on kontent_item_service_item {
                    elements {
                      title {
                        value
                      }
                      slug {
                        value
                      }
                      long_description {
                        value
                        links {
                          link_id
                          url_slug
                          type
                        }
                        images {
                          image_id
                          description
                          url
                          fluid(maxWidth: 350) {
                            ...KontentAssetFluid
                          }
                          fixed(height: 700) {
                            aspectRatio
                            src
                            ...KontentAssetFixed
                          }
                        }
                        modular_content {
                          ... on kontent_item_component_images_wrapper {
                            id
                            elements {
                              description {
                                value
                              }
                              photos {
                                value {
                                  fluid(maxWidth: 350) {
                                    aspectRatio
                                    ...KontentAssetFluid
                                  }
                                  fixed(height: 700) {
                                    aspectRatio
                                    src
                                    ...KontentAssetFixed
                                  }
                                  description
                                  url
                                }
                              }
                            }
                            system {
                              codename
                              type
                            }
                          }
                        }      
                      }
                      teaser_image {
                        value {
                          fluid(maxWidth: 810) {
                            ...KontentAssetFluid
                          }
                          description
                          name
                          url
                        }
                      }
                      teaser_image_alignment__teaser_image_alignment {
                        value {
                          codename
                        }
                      }
                      price {
                        value
                      }
                      time {
                        value
                      }
                    }
                  }
               }
            }
         }
      }
    }
  }
`
