import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import GatsbyImage from 'gatsby-image'
import PageHeading from '../PageHeading'
import ContactUs from '../ContactUs'
import RichText from '../RichText'
import { Link } from 'gatsby'
import { format } from 'react-string-format'

class ServiceTemplateDetails extends React.Component {
    componentDidMount() {
    }

    constructor(props) {
        super(props);

        this.state = {
            index: 0,
            title: "",
            hash: ""
        };
    }

    setValue = (indexValue, titleValue, hashValue) => {
        this.setState({
            index: indexValue,
            title: titleValue,
            hash: hashValue
        });
    };

    render() {
        const serviceTemplateData = this.props.data
        const service = serviceTemplateData.allKontentItemService.nodes[0].elements
        const serviceItems = service.service_item.value
        const serviceCategory = service.title.value
        const serviceCategoryUrl = service.slug.value
        var currentHash = this.props.location.hash

        const items = []
        serviceItems.forEach(serviceItem => {
            items.push({ hash: "#" + serviceItem.elements.slug.value, title: serviceItem.elements.title.value })
        })
        var currentHashIndex = currentHash != "" ? items.findIndex(el => el.hash == currentHash) : 0
        var currentTitle = items[currentHashIndex].title

        const serviceBlock = (
            <div>
                <PageHeading data={(this.state.index == 0 && currentHashIndex != -1) ? currentTitle : currentTitle} category={serviceCategory} />
                <section className="services-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs className="tabs" selectedIndex={(this.state.index == 0 && currentHashIndex != -1) ? currentHashIndex : this.state.index} onSelect="">
                                    <div className="row">
                                        <div className="col-lg-9 order-2">
                                            <section className="tabs-content">
                                                {serviceItems &&
                                                    serviceItems.map(serviceItem => (
                                                        <TabPanel id={`${serviceItem.elements.slug.value}`} key={`${serviceItem.elements.slug.value}`}>
                                                            <div className={`service-thumb service-thumb--${serviceItem.elements.teaser_image_alignment__teaser_image_alignment.value[0] != null ? serviceItem.elements.teaser_image_alignment__teaser_image_alignment.value[0].codename : '--middle'}`}>
                                                                <div className="service-info">
                                                                    <ul>
                                                                        <li><span>Cena:</span> {serviceItem.elements.price.value}</li>
                                                                        {serviceItem.elements.time.value != "" ? (
                                                                            <li><span>Trvání:</span> {serviceItem.elements.time.value}</li>
                                                                        ) : ""}
                                                                    </ul>
                                                                </div>
                                                                {serviceItem.elements.teaser_image.value[0] ? (
                                                                    <GatsbyImage
                                                                        fluid={serviceItem.elements.teaser_image.value[0].fluid}
                                                                        alt={serviceItem.elements.teaser_image.value[0].description}
                                                                        style={{ height: '300px' }}
                                                                    />
                                                                ) : ""}
                                                            </div>
                                                            <div className="down-content">
                                                                <RichText data={serviceItem.elements.long_description} serviceCategory={serviceCategoryUrl} />
                                                            </div>
                                                        </TabPanel>
                                                    ))}
                                            </section>
                                        </div>
                                        <div className="col-lg-3 order-1">
                                            <div className="services-sidebar">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="services-list">
                                                            <TabList>
                                                                <h4>Druh služby</h4>
                                                                <div className="tab-list tab-list--list">
                                                                    {serviceItems &&
                                                                        serviceItems.map((serviceItem, index) => (
                                                                            <Tab
                                                                                key={serviceItem.elements.slug.value}
                                                                                onClick={() => this.setValue(index, serviceItem.elements.title.value, '#' + serviceItem.elements.slug.value)}
                                                                            >
                                                                                <a href={`#${serviceItem.elements.slug.value}`} data-tracking-label={format('Service_Sidebar_{0}', serviceItem.elements.title.value)}>
                                                                                    {serviceItem.elements.title.value}
                                                                                </a>
                                                                            </Tab>
                                                                        ))}
                                                                </div>
                                                                <div className="tab-list tab-list--dropdown">
                                                                    <select
                                                                        className="tab-list__select"
                                                                        onChange={(e) => {
                                                                            this.setValue(e.target.selectedIndex, e.nativeEvent.target[e.target.selectedIndex].text, e.nativeEvent.target[e.target.selectedIndex].value);
                                                                            this.props.location.hash = e.nativeEvent.target[e.target.selectedIndex].value;
                                                                            window.history.pushState({}, '', e.nativeEvent.target[e.target.selectedIndex].value);
                                                                        }}
                                                                        value={this.state.hash != "" ? this.state.hash : this.props.location.hash}
                                                                    >
                                                                        {serviceItems &&
                                                                            serviceItems.map((serviceItem, index) => (
                                                                                <option key={serviceItem.elements.slug.value} value={`#${serviceItem.elements.slug.value}`}>{serviceItem.elements.title.value}</option>
                                                                            ))}
                                                                    </select>
                                                                </div>
                                                            </TabList>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 d-none d-lg-block">
                                                        <div className="services-list">
                                                            <Link to="/doprava-za-klientem" data-tracking-label="Service_SidebarBottom_DopravaZaKlientem">Doprava za klientem</Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <ContactUs />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )

        return (
            <div>
                {serviceBlock}
            </div>
        )
    }
}

export default ServiceTemplateDetails